import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import infographic from '../images/INFOGRAPH.png'
import Footer from '../components/footer'
import InspectionsBanner from '../components/inspectionsbanner';
import CallToAction from "../components/calltoaction"
import Coupon from "../components/coupon"

const NewRoofNeeded = () => (
  <Layout>
    <SEO title="Rayburn's Roofing - Do you need a new roof?" />
    <InspectionsBanner />
    <div class="mx-auto">
        <img class="mx-auto mt-16" src={infographic} alt="A helpful infographic that helps you decide whether or not you need to consider a new roof" />
    </div>
    <br />
    <hr />
    <CallToAction />
    <Footer />
    <Coupon />
  </Layout>
)

export default NewRoofNeeded
